import React from 'react'
import styled from 'styled-components'
import NavButton from '../../system/nav-button'
import VenueCard from './venue-card'
import VenueCardValues from '../../../models/venue-card'

const PACVenueCard = styled(VenueCard)`
  @media (min-width: ${props => props.theme.breakpoints.venueCards}) {
    clip-path: polygon(0 0, 100% 0, calc(100% - 100px) 100%, 0 100%);
    margin-right: -100px;
  }
`

const PACDescription = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.venueCards}) {
    margin-right: 100px;
  }
`

const PACVenue = ({ venueCard }: { venueCard: VenueCardValues }) => (
  <PACVenueCard src="/images/pac-stage.jpg">
    <PACDescription>
      <h2>{venueCard.heading}</h2>
      <p>{venueCard.description}</p>
      <NavButton href="/venue-info#pac" colorTheme="light">
        Learn More <span className="sr-only">about the Performing Arts Center</span>
      </NavButton>
    </PACDescription>
  </PACVenueCard>
)

export default PACVenue
