import React from 'react'
import styled from 'styled-components'
import PACVenue from './pac-venue'
import LoweryVenue from './lowery-venue'
import gql from 'graphql-tag'

const StyledCards = styled.section`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${props => props.theme.breakpoints.venueCards}) {
    grid-template-columns: 1fr 1fr;
  }

  clip-path: polygon(0 0, 100% 50px, 100% 100%, 0 100%);
  margin-top: -50px;
  padding-bottom: 50px;
  background-color: #000000;
`

export const fragment = gql`
  fragment VenueCards on Query {
    venueCards {
      venueId
      heading
      description
    }
  }
`

const VenueCards = ({ data, loading, error }) => {
  const venueCards = data && data.venueCards

  if (!venueCards) {
    return null
  }

  const pac = venueCards.find(venueCard => venueCard.venueId === 'pac')
  const lowery = venueCards.find(venueCard => venueCard.venueId === 'lowery')

  return (
    <StyledCards>
      <PACVenue venueCard={pac} />
      <LoweryVenue venueCard={lowery} />
    </StyledCards>
  )
}

export default VenueCards
