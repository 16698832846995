import React from 'react'
import styled from 'styled-components'
import Spinner from '../icons/spinner'

const StyledSpinner = styled(Spinner)`
  width: 100%;
  margin: auto;
`

const LoadingSpinner = ({ loading }: { loading: boolean }) => {
  if (!loading) return null
  return <StyledSpinner size="5em" />
}

export default LoadingSpinner
