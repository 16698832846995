import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import { colors } from '../../../styles/theme'

const StyledVenueCard = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;

  height: 800px;
  color: ${props => props.theme.colors.typography.light.text};

  @media (min-width: ${breakpoints.ultrawide}) {
    height: 1000px;
  }
`

const StyledPACVenue = styled.div`
  position: relative;
  background-size: cover;
  background-position: center bottom;
  content: '';
  width: 100%;
  grid-area: 1 / 1 / 1 / 1;

  background-image: url(${({ src }: { src: string }) => src});
`

const VenueDescription = styled.div`
  align-self: end;
  z-index: 1;

  padding: 0 ${props => props.theme.spacing.base600};
  padding-bottom: 30px;

  p {
    color: ${colors.neutral300};
  }

  @media (min-width: ${breakpoints.xl}) {
    padding-bottom: 0;
    padding-left: ${props => props.theme.spacing.base800};
    padding-right: ${props => props.theme.spacing.base800};
  }
`

const VenueCard = ({ children, src, ...other }: { src: string; children: any }) => (
  <StyledVenueCard {...other}>
    <StyledPACVenue src={src} />
    <VenueDescription>{children}</VenueDescription>
  </StyledVenueCard>
)

export default VenueCard
