import React from 'react'
import styled, { css } from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import Container from '../system/container/container'
import NavButton from '../system/nav-button'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { colors } from '../../styles/theme'
import { H1Style, H2Style, H3Style, H4Style, H5Style } from '../../styles/headings'
import { bumpRichTextHeading } from '../../utils/rich-text-utils'
import gql from 'graphql-tag'
import { TextColor } from '../../models/text-color'

const heroClipYPosition = '90%'
const heroClipLgYPosition = '80%'

const HeroWrapper = styled.section`
  filter: drop-shadow(${props => props.theme.shadows.close});
`

const StyledHero = styled.div`
  height: 600px;

  clip-path: polygon(0 0, 100% 0, 100% ${heroClipYPosition}, 0 100%);

  @media (min-width: ${breakpoints.xl}) {
    clip-path: polygon(0 0, 100% 0, 100% ${heroClipLgYPosition}, 0 100%);
    height: 800px;
  }

  ${({ color, src }: { color?: TextColor; src?: string }) => css`
    background-position: center;
    background-color: ${color === TextColor.DARK ? colors.neutral100 : colors.primary900};

    ${src &&
      css`
        background-image: url(${src});
      `}
  `}

  background-size: cover;
`

const HeroContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  height: ${heroClipYPosition};
  align-content: end;
  justify-content: end;
  text-align: right;
  padding-bottom: 15px;

  @media (min-width: ${breakpoints.xl}) {
    height: ${heroClipLgYPosition};
  }

  text-shadow: 2px 2px rgba(0, 0, 0, 0.25);

  ${({ color }: { color: TextColor }) =>
    color === TextColor.LIGHT
      ? css`
          &,
          p {
            color: ${props => props.theme.colors.typography.light.text};
          }
        `
      : css`
          &,
          p {
            color: ${props => props.theme.colors.typography.dark.text};
          }
        `}
`

const HeroDescription = styled.div`
  justify-self: end;

  @media (min-width: ${breakpoints.sm}) {
    max-width: 50%;
  }

  h2 {
    ${H1Style}
  }

  h3 {
    ${H2Style}
  }

  h4 {
    ${H3Style}
  }

  h5 {
    ${H4Style}
  }

  h6 {
    ${H5Style}
  }
`

export const fragment = gql`
  fragment HomeHero on Query {
    homeHero {
      description
      image {
        title
        url
      }
      textColor
      eventLink {
        slug
      }
    }
  }
`

const Hero = ({ data, loading, error }) => {
  const eventPromo = data && data.homeHero
  const bumpedHeroDescription =
    eventPromo && eventPromo.description && bumpRichTextHeading(1, eventPromo.description)

  return (
    <HeroWrapper>
      {loading ? (
        <StyledHero />
      ) : (
        <StyledHero
          src={`${eventPromo.image.url}?fm=jpg&fl=progressive&w=2000&fit=fill`}
          aria-label={eventPromo.image.title}
          color={eventPromo.textColor}
        >
          <HeroContainer color={eventPromo.textColor}>
            <HeroDescription>
              {bumpedHeroDescription && documentToReactComponents(bumpedHeroDescription)}

              {eventPromo.eventLink && (
                <NavButton
                  href={`/events/${eventPromo.eventLink.slug}`}
                  colorTheme={eventPromo.textColor === TextColor.LIGHT ? 'light' : 'dark'}
                  justify="right"
                >
                  Learn More
                </NavButton>
              )}
            </HeroDescription>
          </HeroContainer>
        </StyledHero>
      )}
    </HeroWrapper>
  )
}

export default Hero
