import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import styled from 'styled-components'
import { RightArrow } from './icons'

const StyledNavButton = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;

  &.light {
    color: ${props => props.theme.colors.typography.light.text};
    &:hover {
      color: ${props => props.theme.colors.typography.light.linkHoverColor};
    }
  }

  &.dark {
    color: ${props => props.theme.colors.typography.dark.text};
    &:hover {
      color: ${props => props.theme.colors.typography.dark.linkHoverColor};
    }
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
`

const StyledRightArrow = styled(RightArrow)`
  margin-left: ${props => props.theme.spacing.base200};
`

const NavButton = ({
  href,
  children,
  colorTheme = 'dark',
  justify = 'left',
  className = '',
  ...other
}: {
  href: string
  children: any
  colorTheme?: 'dark' | 'light'
  justify?: 'left' | 'right'
  className?: string
}) => (
  (<Link href={href}>

    <StyledNavButton className={classNames(colorTheme, justify, className)} {...other}>
      {children} <StyledRightArrow aria-hidden="true" />
    </StyledNavButton>

  </Link>)
)

export default NavButton
