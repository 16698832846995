import styled, { css } from 'styled-components'
import { fontSizes, colors, fonts } from '../../../styles/theme'

export type InputVariant = 'solid' | 'outlined'
export type InputAttributes = { invalid?: boolean; variant?: InputVariant }

export const inputHeight = '50px'

export const InputStyle = css`
  border: 1px solid ${colors.neutral300};
  width: 100%;
  outline: none;
  padding: 0 12px;
  font-family: ${fonts.openSans};

  height: ${inputHeight};
  font-size: ${fontSizes.size500};
  line-height: ${fontSizes.lineHeight500};

  color: ${props => props.theme.colors.typography.dark.text};
  font-family: ${props => props.theme.fontFamily.bodyCopy};

  border-radius: 2px;
  background-color: ${colors.neutral100};

  &::placeholder {
    font-style: italic;
    color: ${colors.neutral400};
  }

  ${({ invalid = false }: InputAttributes) =>
    invalid &&
    css`
      border-left: 4px solid ${colors.danger};
    `}

  ${({ variant }: InputAttributes) =>
    variant === 'outlined' &&
    css`
      background: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${colors.neutral900};
      padding-left: 0;
    `}
`

const Input = styled.input`
  ${InputStyle}
`

export default Input
