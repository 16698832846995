import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import Button from './button'

const ConnectedInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  align-items: center;
  row-gap: ${props => props.theme.spacing.base300};

  input {
    width: 100%;
    border: 0;
    padding-left: ${props => props.theme.spacing.base300};
    padding-right: ${props => props.theme.spacing.base300};
    border-radius: 2px;
  }

  ${Button} {
    height: 100%;
  }

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr auto;

    input {
      border-radius: 2px 0 0 2px;
    }

    ${Button} {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`

export default ConnectedInputs
