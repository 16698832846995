import { Document } from '@contentful/rich-text-types'

export const bumpRichTextHeading = (bumpOffset: number, richText: Document): Document => {
  if (!richText) {
    return null
  }

  const headingRegex = /heading-(\d)/
  const headingBumpedContent = richText.content.map((node: any) => {
    let newNode = node

    const match = headingRegex.exec(node.nodeType)
    if (match != null) {
      const headingLevel = +match[1]
      const newHeadingLevel = Math.min(headingLevel + bumpOffset, 6)
      const newNodeType = `heading-${newHeadingLevel}`
      newNode = { ...node, nodeType: newNodeType }
    }

    return newNode
  })
  const headingBumpedRichText = { ...richText, content: headingBumpedContent }
  return headingBumpedRichText
}
