import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
  width: 100%;
  height: 700px;
  /* filter: grayscale(100%); */
`

const GoogleMaps = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`

const Map = () => (
  <Section>
    <GoogleMaps
      title="Allen Performing Arts Center on Google Maps"
      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJp84Q9MsQTIYRw7X4rGl2V2w&key=AIzaSyAd5R9l6GKBvY_1qqo1ipfz3YvO6o_O7Go"
      frameBorder={0}
    />
  </Section>
)

export default Map
