import React from 'react'
import Layout from '../components/system/layout'
import Hero, { fragment as HomeHeroFragment } from '../components/home/hero'
import UpcomingEvents, {
  fragment as UpcomingEventsFragment
} from '../components/home/upcoming-events'
import VenueCards, {
  fragment as VenueCardsFragment
} from '../components/home/venue-card/venue-cards'
import Map from '../components/home/map'
import Newsletter from '../components/home/newsletter'
import Meta from '../components/system/meta'
import { withApollo } from '../apollo/apollo'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const INDEX_QUERY = gql`
  query Index {
    ...HomeHero
    ...UpcomingEvents
    ...VenueCards
  }

  ${HomeHeroFragment}
  ${UpcomingEventsFragment}
  ${VenueCardsFragment}
`

const Index = () => {
  const { data, loading, error } = useQuery(INDEX_QUERY)

  return (
    <Layout extendedFooter={true}>
      <Meta />
      <h1 className="sr-only">AISD Performing Arts Center</h1>
      <Hero data={data} loading={loading} error={error} />
      <UpcomingEvents data={data} loading={loading} error={error} />
      <VenueCards data={data} loading={loading} error={error} />
      <Map />
      <Newsletter />
    </Layout>
  )
}

export default withApollo(Index)
