import React, { useState, FormEvent } from 'react'
import styled from 'styled-components'
import Container from '../system/container/container'
import LoadingButton, { ButtonStatus } from '../system/forms/loading-button'
import breakpoints from '../../styles/breakpoints'
import Input from '../system/forms/input'
import ConnectedInputs from '../system/forms/connected-inputs'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const Wrapper = styled.section`
  position: relative;
  width: 100%;
`

const Background = styled(Container)`
  position: relative;
  text-align: center;
  background-color: #324561;
  &,
  p {
    color: ${props => props.theme.colors.typography.light.text};
  }
  padding: ${props => props.theme.spacing.base500};
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    margin-top: 0;
  }

  form {
    text-align: left;
  }
`

const StyledContainer = styled(Container)`
  display: block;
  width: 100%;
`

const SUBSCRIBE = gql`
  mutation Subscribe($email: String!) {
    subscribeToNewsletter(email: $email) {
      status
    }
  }
`

const StatusMessage = styled.p`
  margin-bottom: 0;
`

const Newsletter = () => {
  const [email, setEmail] = useState('')
  const [subscribe, { data, loading, error, called }] = useMutation(SUBSCRIBE)
  const subscribeStatus = data && data.subscribeToNewsletter.status

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    subscribe({
      variables: {
        email
      }
    })
  }

  let buttonStatus: ButtonStatus = 'initial'
  let statusMessage = ''
  if (loading) {
    buttonStatus = 'loading'
  } else if (error || (called && subscribeStatus === 'ERROR')) {
    buttonStatus = 'error'
    statusMessage =
      'An unknown error occurred while subscribing to the newsletter. Please try again later.'
  } else if (called && subscribeStatus === 'SUBSCRIBED') {
    buttonStatus = 'success'
    statusMessage = 'Thanks! You have been subscribed to newsletter.'
  } else if (called && subscribeStatus === 'ALREADY_SUBSCRIBED') {
    buttonStatus = 'success'
    statusMessage = `It looks like you're already subscribed, awesome!`
  }

  return (
    <Wrapper>
      <Background>
        <StyledContainer>
          <h2>Subscribe To Our Newsletter!</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="newsletterEmail" className="sr-only">
              Email
            </label>
            <fieldset disabled={called}>
              <ConnectedInputs>
                <Input
                  type="email"
                  placeholder="Email"
                  id="newsletterEmail"
                  name="newsletterEmail"
                  aria-label="Your email to receive our newsletter"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
                <LoadingButton status={buttonStatus} type="submit">
                  Subscribe
                </LoadingButton>
              </ConnectedInputs>
            </fieldset>
          </form>
          {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
        </StyledContainer>
      </Background>
    </Wrapper>
  )
}

export default Newsletter
