import React from 'react'
import styled from 'styled-components'
import Container from '../system/container/container'
import NavButton from '../system/nav-button'
import EventsGrid from '../events/events-grid'
import gql from 'graphql-tag'
import { EventFragment } from '../events/event-fragment'
import PACEvent from '../../models/event/pac-event'
import LoadingSpinner from '../system/loading/loading-spinner'

const Background = styled.section`
  width: 100%;
  background: url('/images/textures/gray-poly.jpg');
  margin-top: -200px;
  padding-top: 230px;
`

const EventsContainer = styled(Container)`
  display: grid;
  padding-bottom: ${props => props.theme.spacing.base700};
`

const StyledEventsGrid = styled(EventsGrid)`
  margin-bottom: ${props => props.theme.spacing.base600};
`

const ViewAllEventsButton = styled(NavButton)`
  justify-self: end;
`

const NoEvents = styled.p`
  font-style: italic;
`

export const fragment = gql`
  fragment UpcomingEvents on Query {
    allEvents(limit: 6) {
      ...PACEvent
    }
  }

  ${EventFragment}
`

const UpcomingEvents = ({ data, loading, error }) => {
  const events = (data && (data.allEvents as PACEvent[])) || []

  return (
    <Background>
      <EventsContainer>
        <h2>Upcoming Events</h2>
        <LoadingSpinner loading={loading} />
        {events.length > 0 ? (
          <StyledEventsGrid variant="card" events={events} />
        ) : (
          <NoEvents>There are no upcoming events. Check back soon!</NoEvents>
        )}
        <ViewAllEventsButton href="/events" justify="right">
          View All Events
        </ViewAllEventsButton>
      </EventsContainer>
    </Background>
  )
}

export default UpcomingEvents
