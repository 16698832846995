import React from 'react'
import styled from 'styled-components'
import NavButton from '../../system/nav-button'
import VenueCard from './venue-card'
import VenueCardValues from '../../../models/venue-card'

const LoweryVenueCard = styled(VenueCard)`
  clip-path: polygon(0 30px, 100% 0, 100% 100%, 0 100%);

  @media (min-width: ${props => props.theme.breakpoints.venueCards}) {
    clip-path: polygon(100px 0, 100% 0, 100% 100%, 0 100%);
  }
`

const LoweryDescription = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.venueCards}) {
    padding-left: 40px;
  }
`

const LoweryVenue = ({ venueCard }: { venueCard: VenueCardValues }) => (
  <LoweryVenueCard src="/images/lowery.jpg">
    <LoweryDescription>
      <h2>{venueCard.heading}</h2>
      <p>{venueCard.description}</p>
      <NavButton href="/venue-info#lowery" colorTheme="light">
        Learn More <span className="sr-only">about the Lowery Freshman Center</span>
      </NavButton>
    </LoweryDescription>
  </LoweryVenueCard>
)

export default LoweryVenue
